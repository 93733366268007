.headingMain {
    text-align: center;
    margin-top: 20px;
    font-size: 2em;
  }
  
  .subHeading {
    text-align: center;
    margin-top: 10px;
    font-size: 1.5em;
  }
  
  .category-container {
    padding: 25px;
  }
  
  .templateCategory {
    text-align: left;
    margin: 20px 0;
    font-size: 30px;
    text-decoration: underline 5px;
    text-underline-offset: 5px; /* Adjust the offset as needed */
    padding-bottom: 10px;
    /* font-weight: bold;
    text-decoration: underline; */
  }
  
  @media(max-width: 1259px){
    .templetContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
        padding-bottom: 50px;
    }
    
    .wordpressDesktop{
        display: none;
    }
    .wordpressMobileOrTab{
        /* display: flex; */
    }
  }
  @media(min-width: 1260px){
    .templetContainer {
        display: flex;
        justify-content: center;
        gap: 5px;
        padding-bottom: 50px;
    }

    .wordpressDesktop{
        /* display: flex; */
    }
    .wordpressMobileOrTab{
        display: none;
    }
  }
  
  .box {
    flex: 1 1 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    opacity: 0;
    animation: fadeIn 1s forwards;
  }
  
  .box:nth-child(1) {
    animation-delay: 0s;
  }
  
  .box:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .box:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .box:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  .resized-image {
    max-width: 320px;
    height: auto;
    display: block;
    border: 2px solid #ccc;
    box-shadow: 0 4px 8px rgba(196, 55, 55, 0.1);
    border-radius: 3px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .resized-image:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 16px rgba(143, 93, 93, 0.2);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .side-by-side {
    display: flex;
    justify-content: space-between;
  }
  
  .side-by-side .category-container {
    flex: 1;
    padding: 25px;
  }
  