
/* Footer */
#footer{
  background-color: #212529;
  color: whitesmoke;
}
.footerContainer{
  width: 100%;
  overflow: hidden; /* Clear float */
  /* background-color: yellow; */
}
.footerLeft{
  float: left;
  box-sizing: border-box; /* Include padding and border in the width */
  padding: 20px;
  padding-right: 30px;
  text-align: left;
}
.footerRight{
  float: left;
  box-sizing: border-box;
  padding: 10px;
  text-align: left;
  height: 50%;
  /* text-align: right; */
}

.rightContainer{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
}
.socialMediaDiv{
  padding: 20px;
}
@media(min-width: 768px){
  .footerLeft{
    width: 70%;
  }
  .rightContainer{
    height: 50vh;  /* Full height of the viewport */
  }
}

.footerBottom p {
  margin: 0;
  color: #6c757d; /* Text color of your choice */
}
.footerMsgBtn{
  background-color: #BF0A30;
  color: whitesmoke;
  border-color: transparent;
  font-size: 14px;
  text-decoration: none;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
}
.socialmediaLogo{
  width: 30px;
  /* padding-right: 5px; */
  margin-top: 20px;
  margin-right: 10px;
  margin-bottom: 40px;
}