.careerContaint{
    margin-top: 100px;
}
@media(max-width: 372px){
    .careerPrefix{
        font-size: 20px;
    }
    .careerSelection{
        font-size: 25px;
        cursor: pointer;
    }
    .careerElement{
        font-size: 25px;
    }
    .formHead{
        font-size: 23px;
        font-weight: bold;
    }
    .genderAdjustment{
        display: block;
    }
}
@media(min-width: 372px){
    .careerPrefix{
        font-size: 22px;
    }
    .careerSelection{
        font-size: 30px;
        cursor: pointer;
    }
    .careerElement{
        font-size: 30px;
    }
    .formHead{
        font-size: 23px;
        font-weight: bold;
    }
    .genderAdjustment{
        display: block;
    }
    /* .freelanceWorkMsg{
        margin-top: "175px";
    } */
}
@media(min-width: 525px){
    .careerPrefix{
        font-size: 35px;
    }
    .careerSelection{
        font-size: 50px;
        cursor: pointer;
    }
    .careerElement{
        font-size: 50px;
    }
    .formHead{
        font-size: 30px;
        font-weight: bold;
    }
    .genderAdjustment{
        display: none;
    }
}
.freelanceWorkMsg{
    margin-top: "140px";
    /* margin-top: "500px"; */
    margin-left: "20px";
    text-align: "left";
}
.careerSelection:hover{
    color: #bf0a30;
}
.careerSelection.active {
    font-weight: bold;
    color: #bf0a30; /* or any other style you prefer for the active state */
    text-decotation: none !important;
}
.fontBody{
    margin-top: 30px;
    margin-bottom: 30px;
}


#dob::selection{
    background-color: red;
}

/* styles.css */
select option:hover {
    background-color: #e34a50;
    color: white; /* Optional: change text color to contrast the background */
}
