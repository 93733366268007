.careerContaint{
    margin-top: 100px;
}
@media(max-width: 372px){
    #clientForm{
        margin: auto;
        width: 100%;
    }
    .careerPrefix{
        font-size: 20px;
    }
    .careerSelection{
        font-size: 25px;
        cursor: pointer;
    }
    .careerElement{
        font-size: 25px;
    }
    .clientFormPageHead{
        font-size: 20px;
        font-weight: bold;
        color: #bf0a30;
    }
    .clientFormHead{
        font-size: 25px;
        font-weight: bold;
    }
    .genderAdjustment{
        display: block;
    }
}
@media(min-width: 372px){
    #clientForm{
        margin: auto;
        width: 100%;
    }
    .careerPrefix{
        font-size: 22px;
    }
    .careerSelection{
        font-size: 30px;
        cursor: pointer;
    }
    .careerElement{
        font-size: 30px;
    }
    .clientFormPageHead{
        font-size: 23px;
        font-weight: bold;
        color: #bf0a30;
    }
    .clientFormHead{
        font-size: 30px;
        font-weight: bold;
    }
    .genderAdjustment{
        display: block;
    }
    /* .freelanceWorkMsg{
        margin-top: "175px";
    } */
}
@media(min-width: 524px){
    #clientForm{
        margin: auto;
        width: 90%;
    }
    /* .gapAfterWeb{
        margin: 20px;
    } */
    .careerPrefix{
        font-size: 35px;
    }
    .careerSelection{
        font-size: 50px;
        cursor: pointer;
    }
    .careerElement{
        font-size: 50px;
    }
    .clientFormPageHead{
        font-size: 30px;
        font-weight: bold;
        color: #bf0a30;
    }
    .clientFormHead{
        font-size: 40px;
        font-weight: bold;
    }
    .genderAdjustment{
        display: none;
    }
}
@media(min-width: 1110px){
    #clientForm{
        margin: auto;
        width: 80%;
    }
}
.freelanceWorkMsg{
    margin-top: "140px";
    /* margin-top: "500px"; */
    margin-left: "20px";
    text-align: "left";
}
.careerSelection:hover{
    color: #bf0a30;
}
.careerSelection.active {
    font-weight: bold;
    color: #bf0a30; /* or any other style you prefer for the active state */
    text-decotation: none !important;
}
.fontBody{
    margin-top: 30px;
    margin-bottom: 30px;
}
