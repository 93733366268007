/* // Some CSS are in main style.css (src/components/style.css) */


.blogElements{
    width: 100%;
    overflow: hidden; /* Clear float */
}

.blog-page-section{
  padding-top: 6rem;
}
    
@media (max-width: 895px) {
    .blogDetailLeft{
        /* background-color: red; */
        /* box-sizing: border-box; Include padding and border in the width */
        padding: 20px;
        text-align: center;
    }
    .blogDetailRight{
        /* float: left; */
        /* box-sizing: border-box; Include padding and border in the width */
        /* padding: 20px; */
        text-align: center;
    }
    .blogPic{
        width: 100%;
        padding-top: 10px;
    }
    .blogBrief{
        width: 100%;
    }
    .blogDecHideUnhide{
        display: none;
    }
    .blogContainer{
        position: relative;
        width: 100%;
        height: 100%; /* Adjust the height as needed */
        overflow: hidden;
    }
    .blogImage{
        position: fixed;
        top: 1;
        left: 0;
        width: 100%;
        z-index: -1; /* Places the image behind the content */
        opacity: 0.5;
    }
    .blogContent{
        position: relative;
        color: black; /* Optional: text color for better readability */
        z-index: 1; /* Ensures the content is above the image */
        display: flex; /* Optional: to center content inside the div */
        justify-content: center; /* Optional: to center content horizontally */
        align-items: center; /* Optional: to center content vertically */
        height: 100%; /* Ensures the content takes full height of the div */
    }
    .adjustCompressImage{
        width: 90%;
    }
    .adjustCompressText{
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 895px) {
    .blogDetailLeft{
        float: left;
        box-sizing: border-box; /* Include padding and border in the width */
        padding: 20px;
        text-align: left;
    }
    .blogDetailRight{
        float: left;
        box-sizing: border-box; /* Include padding and border in the width */
        padding: 20px;
        text-align: right;
    }

    .blogPic{
        width: 40%;
    }
    .blogBrief{
        width: 60%;
    }
    .blogBriefHideUnhide{
        display: none;
    }
    .blogContainer{
        position: relative;
        width: 100%;
        height: 100%; /* Adjust the height as needed */
        overflow: hidden;
    }
    .blogImage{
        position: fixed;
        width: 45%;
        transform: translate(-50%);
        z-index: -1; /* Places the image behind the content */
        opacity: 0.5;
    }
    .blogContent{
        position: relative;
        color: black; /* Optional: text color for better readability */
        z-index: 1; /* Ensures the content is above the image */
        display: flex; /* Optional: to center content inside the div */
        justify-content: center; /* Optional: to center content horizontally */
        align-items: center; /* Optional: to center content vertically */
        height: 100%; /* Ensures the content takes full height of the div */
    }
}

.blogHeads{
    font-size: 30px;
    text-decoration: underline 5px;
    text-underline-offset: 5px; /* Adjust the offset as needed */
    padding-bottom: 10px;
}
.blog-link{
    text-decoration: none;
    color: black;
}
.blogElements:hover .readDetail{
    color: black;
    text-decoration: underline red 2px;
    text-underline-offset: 5px;
}

.imageEffect{
    display: inline-block;
    overflow: hidden;
    /* Adjust the size of the container as needed */
    width: 100%;
    /* height: 200px; */
    position: relative;
    perspective: 1000px; /* Optional, for a more pronounced 3D effect */
    transform: translateY(5px);
}
.imageEffect img{
    width: 100%;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.imageEffect:hover{
    /* box-shadow: 10px 10px 5px pink; */
    transform: translateY(0px); /* Raise the image by 5 pixels (as initialy it was 5px, raizing by 5px to make it 0px) */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3); /* Optional, for a shadow effect */
}

/* Blog pages */
.blogBody{
    margin-top: 125px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 100px;
}
@media(max-width: 580px) {
    .blogHead{
        margin-bottom: 30px;
        font-size: 30px;
    }
}
@media(min-width: 580px) {
    .blogHead{
        margin-bottom: 30px;
        font-size: 50px;
    }
}
.blogImg{
    margin-bottom:30px;
    width: 80%;
    height: auto;
}
.blogMeterial{
    font-size: 20px;
    text-align: justify;
    margin: auto;
    width: 100%;
}