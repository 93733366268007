body{
    margin: 0;
    user-select: none;
}

/* ::selection {
    background-color: #e34a50;
} */

/* Navbar.css */
#SAHmainNav {
    background-color: #212529;
    /* background-color: rgba(0, 0, 0, 0.8); */
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    --bs-navbar-color: rgba(0, 0, 0, 0.55);
}

.logo {
    margin-left: 1rem;
    /* margin-left: 1.5rem; */
    /* margin-left: 50%; */
}

.ms-1 {
    margin-left: 0.25rem !important;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}
.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
        transition: none;
    }
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

@media (min-width: 200px) {
    .navbar-expand-xs .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        flex-wrap: wrap;
    }
    .navbar-expand-xs .navbar-tabs {
        flex: 1 0 300px; 
        /* background-color: #fff;  */
        /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */
        padding: 20px; 
        border-radius: 10px;
        transition: transform 0.3s ease-in-out;
    }
    .navbar-collapse{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 80%;
    }
    .freeconsultcheckfont{
        font-size: 20px;
    }
}
@media (min-width: 400px) {
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-collapse{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 80%;
    }
    .freeconsultcheckfont{
        font-size: 20px;
    }
}
@media (min-width: 440px) {
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-collapse{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 80%;
    }
    .freeconsultcheckfont{
        font-size: 25px;
    }
}
@media (min-width: 465px) {
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-collapse{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 70%;
    }
    .freeconsultcheckfont{
        font-size: 25px;
    }
}
@media (min-width: 460px) {
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-collapse{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 75%;
    }
    .freeconsultcheckfont{
        font-size: 25px;
    }
}
@media (min-width: 550px) {
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-collapse{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 70%;
    }
    .freeconsultcheckfont{
        font-size: 25px;
    }
}
@media (min-width: 576px) {
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-collapse{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 70%;
    }
    .freeconsultcheckfont{
        font-size: 25px;
    }
}
@media (min-width: 768px) {
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-collapse{
        visibility: hidden;
    }
    .navbar-toggler{
        visibility: visible;
    }
    .freeconsultcheck{
        width: 100%;
    }
    .freeconsultcheckfont{
        font-size: 25px;
    }
}
@media (min-width: 895px) {
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-collapse{
        visibility: visible;
    }
    .navbar-toggler{
        visibility: hidden;
    }
    .virticalNavContainer{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 100%;
    }
    .freeconsultcheckfont{
        font-size: 25px;
    }
}
@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-toggler{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 100%;
    }
    .freeconsultcheckfont{
        font-size: 30px;
    }
}
@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-toggler{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 80%;
    }
    .freeconsultcheckfont{
        font-size: 30px;
    }
}
@media (min-width: 1400px) {
    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-toggler{
        visibility: hidden;
    }
    .freeconsultcheck{
        width: 60%;
    }
    .freeconsultcheckfont{
        font-size: 30px;
    }
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}
.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-flush .accordion-collapse {
    border-width: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0;
}

#SAHmainNav .navbar-toggler {
    padding: 0.50rem;
    margin-right: 0.5rem;
    font-size: 0.90rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-transform: uppercase;
    font-weight: 700;
}

.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: whitesmoke;
    /* color: var(--bs-navbar-color); */
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
        transition: none;
    }
}
.navbar-toggler:hover {
    text-decoration: none;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}


.navbar-tabs {
    list-style-type: none;
    /* margin: 0; */
    /* padding: 0; */
    /* align-items: center; */
    display: flex;
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.navbar-tab {
    padding: 14px 20px;
    cursor: pointer;
    color: grey;
}

.navbar-tab:hover {
    color: #dc3545;
    /* background-color: black; */
}

.active {
    color: #dc3545;
    /* text-decoration: underline #dc3545; */
    text-decoration: underline whitesmoke;
    text-underline-offset: 5px;
}

.inquiryButton{
    display: flex;
    position: absolute;
    top: 25px;
    right: 0;
    margin-right: 30px;
}

#inquiry{
    background-color: #BF0A30;
    border: none;
    border-radius: 8px;
    color: whitesmoke;
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}
#activeinquiry{
    background-color: whitesmoke;
    border: none;
    border-radius: 8px;
    color: #BF0A30;
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}

.virticalNavHandeler{
    z-index: 1000;
    padding: 0;
}
.virticalNavContainer{
    position: fixed;
    top: 0;
    right: 0;
    width: 270px;
    height: 2000px;
    background-color: black;
    z-index: 500;
}
.virticalNavClose{
    float: right;
    cursor: pointer;
    padding: 20px;
    margin-right: 10px;
    font-size: 28px;
    color: whitesmoke;
}
.virticalNavBar{
    margin-top: 150px;
    height: 500px;
    /* background-color: red; */
}
.virticalNavItems{
    text-align: left;
    cursor: pointer;
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: whitesmoke;
}
.virticalNavInquiry{
    display: flex;
    position: absolute;
    cursor: pointer;
    top: 450px;
    left: 20px;
    margin-right: 30px;
}

#content{
    margin-top: 80px;
}
#contentPage{
    margin-top: 50px;
}


/* About */
.sectionHeadHome{
    font-size: 50px;
    font-weight: bold;
    padding-bottom: 20px;
}
@media(max-width: 450px){
.sectionHeadHome{
    font-size: 25px;
    font-weight: bold;
    padding-bottom: 20px;
}
}
/* @media(min-width: 700px){
.sectionHeadHome{
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 20px;
}
} */
@media(min-width: 701px){
.sectionHeadHome{
    font-size: 50px;
    font-weight: bold;
    padding-bottom: 20px;
}
}
.aboutPara p{
font-size: 20px;
text-align: justified;
}


/* Privacy Policy */
#privacyPolicy a{
    color: #bf0a30;
}


/* Error Page */
.custom-list {
    list-style-type: none; /* Remove default bullet points */
    padding: 0;
}

.custom-list li::before {
    content: '>';
    margin-right: 8px; /* Space between the ">" and the text */
    color: #bf0a30; /* Color of the ">" symbol */
    font-weight: bold;
}
  