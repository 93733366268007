/* // Some CSS are in main style.css (src/components/style.css) */


.serviceElements{
    width: 100%;
    overflow: hidden; /* Clear float */
}
    
@media (max-width: 895px) {
    .serviceDetailLeft{
        /* background-color: red; */
        /* box-sizing: border-box; Include padding and border in the width */
        /* padding: 20px; */
        text-align: center;
    }
    .serviceDetailRight{
        /* float: left; */
        /* box-sizing: border-box; Include padding and border in the width */
        /* padding: 20px; */
        text-align: center;
    }
    .servicePic{
        width: 100%;
        padding-top: 10px;
    }
    .serviceBrief{
        width: 100%;
    }
    .serviceDecHideUnhide{
        display: none;
    }
    .serviceContainer{
        position: relative;
        width: 100%;
        height: 100%; /* Adjust the height as needed */
        overflow: hidden;
    }
    .serviceImage{
        position: fixed;
        top: 1;
        left: 0;
        width: 100%;
        z-index: -1; /* Places the image behind the content */
        opacity: 0.5;
    }
    .serviceContent{
        position: relative;
        color: black; /* Optional: text color for better readability */
        z-index: 1; /* Ensures the content is above the image */
        display: flex; /* Optional: to center content inside the div */
        justify-content: center; /* Optional: to center content horizontally */
        align-items: center; /* Optional: to center content vertically */
        height: 100%; /* Ensures the content takes full height of the div */
    }
}

@media (min-width: 895px) {
    .serviceDetailLeft{
        float: left;
        box-sizing: border-box; /* Include padding and border in the width */
        padding: 20px;
        text-align: left;
    }
    .serviceDetailRight{
        float: left;
        box-sizing: border-box; /* Include padding and border in the width */
        padding: 20px;
        text-align: right;
    }

    .servicePic{
        width: 40%;
    }
    .serviceBrief{
        width: 60%;
    }
    .serviceBriefHideUnhide{
        display: none;
    }
    .serviceContainer{
        position: relative;
        width: 100%;
        height: 100%; /* Adjust the height as needed */
        overflow: hidden;
    }
    .serviceImage{
        position: fixed;
        width: 45%;
        transform: translate(-50%);
        z-index: -1; /* Places the image behind the content */
        opacity: 0.5;
    }
    .serviceContent{
        position: relative;
        color: black; /* Optional: text color for better readability */
        z-index: 1; /* Ensures the content is above the image */
        display: flex; /* Optional: to center content inside the div */
        justify-content: center; /* Optional: to center content horizontally */
        align-items: center; /* Optional: to center content vertically */
        height: 100%; /* Ensures the content takes full height of the div */
    }
}

.serviceHeads{
    font-size: 30px;
    text-decoration: underline 5px;
    text-underline-offset: 5px; /* Adjust the offset as needed */
    padding-bottom: 10px;
}
.service-link{
    text-decoration: none;
    color: black;
}
.serviceElements:hover .readDetail{
    color: black;
    text-decoration: underline red 2px;
    text-underline-offset: 5px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between; /* Adjust spacing between buttons */
    gap: 10px; /* Optional: Adjust the gap between buttons */
}
@media(max-width: 895px){
    .positionUpDown{
        display: block;
        margin: 20px;
    }
    .buttonContainer {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
        align-items: center;
    }
    .ecomButtonCointainer {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
        align-items: center;
        height: 10vh; /* Optional: Adjust based on your needs */
        margin-top: 5vh;
    }
}
@media(min-width: 895px){
    .buttonCointainer{
        display: flex;
        position: relative;
    }
    .firstButton{
        position: absolute;
        right: 200px;
    }
    .secondButton{
        position: absolute;
        right: 0;
    }
    .secondButtonLeft{
        margin-left: 50px;
    }
    .ecomButtonCointainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 10vh; /* Optional: Adjust based on your needs */
    }
    .ecomFirstButton{
        position: absolute;
        margin-right: 200px;
    }
    .ecomSecondButton{
        position: absolute;
        margin-left: 200px;
    }
}

.imageEffect{
    display: inline-block;
    overflow: hidden;
    /* Adjust the size of the container as needed */
    width: 100%;
    /* height: 200px; */
    position: relative;
    perspective: 1000px; /* Optional, for a more pronounced 3D effect */
    transform: translateY(5px);
}
.imageEffect img{
    width: 100%;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.imageEffect:hover{
    /* box-shadow: 10px 10px 5px pink; */
    transform: translateY(0px); /* Raise the image by 5 pixels (as initialy it was 5px, raizing by 5px to make it 0px) */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3); /* Optional, for a shadow effect */
}