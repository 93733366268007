/* Contact */
.sectionHead{
    font-size: 50px;
    font-weight: bold;
    padding-bottom: 20px;
}
/* @media(max-width: 450px){
.sectionHead{
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 20px;
}
}
@media(min-width: 450px){
.sectionHead{
    font-size: 50px;
    font-weight: bold;
    padding-bottom: 20px;
}
} */
.contactPara p{
    font-size: 20px;
    text-align: justified;
}

.contactEmail{
    text-decoration: none;
    font-size: 22px;
    color: black;
    font-weight: bold;
}
.contactEmail:hover{
    color: #bf0a30;
    font-weight: bold;
}

@media(max-width: 768px){
    .contactImage{
        width:80%;
    }
    .contactEmailImg{
        width:20%;
        transform: rotate(335deg);
    }
}
@media(min-width: 768px){
    .contactImage{
        width:50%;
    }
    .contactEmailImg{
        width:10%;
        transform: rotate(335deg);
    }
}